import { useEffect, useState } from 'react';
import { BuzzesList } from './BuzzesList';
import { PlayerConnectionStatusList } from './PlayerConnectionStatusList';
import { getIoUpdate } from '../helpers/api';
import { GameModeControls } from './GameModeControls';
import { HostLoginForm } from './HostLoginForm';
import { TeamOverridePointsList } from './TeamOverridePointsList';
import { HostBuzzerControls } from './HostBuzzerControls';
import { hostSocket } from '../../socket';
import { BuzzedUser, GameData } from '../../types';

type HostParams = {
  buzzes: BuzzedUser[];
  gameData: GameData;
  isBuzzerLocked: boolean;
  isTeamGame: boolean;
};

export const Host = ({
  isBuzzerLocked,
  buzzes,
  gameData,
  isTeamGame,
}: HostParams) => {
  const [isDangerZoneOpen, setIsDangerZoneOpen] = useState(false);
  const [isAllowedAccess, setIsAllowedAccess] = useState(false);
  const [password, setPassword] = useState('');
  const name = 'hostControls';

  useEffect(() => {
    hostSocket.connect();

    const buzzHostStored = sessionStorage.getItem(name);
    if (buzzHostStored) {
      const account = JSON.parse(buzzHostStored);
      if (account.id === 'hostAccount') {
        setIsAllowedAccess(true);
      }
    }
    getIoUpdate();

    return () => {
      hostSocket.disconnect();
    };
  }, []);

  const handleOpenDangerZone = () => {
    if (buzzes.length === 0) {
      setIsDangerZoneOpen((prev) => !prev);
    }
  };

  if (!isAllowedAccess)
    return (
      <div className="mainContainer">
        <HostLoginForm
          name={name}
          password={password}
          setPassword={setPassword}
          setIsAllowedAccess={setIsAllowedAccess}
        />
      </div>
    );

  return (
    <div className="mainContainer">
      <h1>Host</h1>
      <div className="hostListContainer">
        {isTeamGame && (
          <div className="hostList">
            <TeamOverridePointsList gameData={gameData} />
          </div>
        )}
        <div className="hostListPlayer">
          <PlayerConnectionStatusList
            gameData={gameData}
            isTeamGame={isTeamGame}
            buzzed={buzzes.length}
          />
        </div>
      </div>
      <div className="hostSectionContainer">
        <HostBuzzerControls
          isBuzzerLocked={isBuzzerLocked}
          buzzed={buzzes.length}
        />
        <BuzzesList
          gameData={gameData}
          isTeamGame={isTeamGame}
          buzzes={buzzes}
        />
      </div>
      <button onClick={handleOpenDangerZone}>danger zone controls</button>
      {isDangerZoneOpen && <GameModeControls isTeamGame={isTeamGame} />}
    </div>
  );
};
