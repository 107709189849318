import { useEffect, useState } from 'react';
import { BuzzedUser, colors } from '../../types';

type BroadcastBuzzesParams = {
  buzzes: BuzzedUser[];
  isTeamGame: boolean;
};

export const BroadcastBuzzes = ({
  buzzes,
  isTeamGame,
}: BroadcastBuzzesParams) => {
  const [firstBuzz, setFirstBuzz] = useState<number>(0);

  useEffect(() => {
    if (buzzes.length) {
      if (buzzes[0].timeBuzzed) {
        setFirstBuzz(buzzes[0].timeBuzzed);
      }
    }
  }, [buzzes]);

  return buzzes.length ? (
    <div className="squadPoints">
      <ol>
        {buzzes.slice(0, 10).map((b, index) => (
          <li
            key={index}
            style={{
              backgroundColor: colors[b.team],
              width: '1200px',
              padding: '0px',
            }}
          >
            <h5 className="buzzesNumber">{index + 1}</h5>
            <div className="playerAndTeam">
              <h5>{b?.name}</h5>
              {isTeamGame && (
                <>
                  <h6 className="buzzesFrom">FROM</h6>
                  <h5>{b.team.toUpperCase()}</h5>
                </>
              )}
              <h6 className="buzzesSeconds">
                {index !== 0 && ` +${(b?.timeBuzzed - firstBuzz) / 1000}`}
              </h6>
            </div>
          </li>
        ))}
      </ol>
    </div>
  ) : (
    <h5> </h5>
  );
};
