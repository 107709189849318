import { User } from '../../types';

type HostLoginFormParams = {
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setIsAllowedAccess: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
};

export const HostLoginForm = ({
  password,
  setPassword,
  setIsAllowedAccess,
  name,
}: HostLoginFormParams) => {
  const handlePasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password === 'lila') {
      const host: User = {
        id: 'hostAccount',
        name,
        team: name,
      };
      localStorage.setItem(name, JSON.stringify(host));
      setIsAllowedAccess(true);
    }
  };

  return (
    <form onSubmit={(e) => handlePasswordSubmit(e)}>
      <h2>Host ?</h2>
      <input
        placeholder="password"
        type="text"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      ></input>
      <button type="submit">submit</button>
    </form>
  );
};

//delete this msg
