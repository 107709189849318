import { User } from '../../../../types';
import './PlayerDetails.css';

type PlayerDetailsParams = { user: User };

export const PlayerDetails = ({ user }: PlayerDetailsParams) => (
  <section className="player-details-container">
    <div className="title-pair">
      <h4>PLAYER</h4>
      <h5>{user.name.toUpperCase()}</h5>
    </div>
    <div className="title-pair">
      <h4>TEAM</h4>
      <h5>{user.team.toUpperCase()}</h5>
    </div>
  </section>
);
