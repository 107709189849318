import { useEffect, useRef, useState } from 'react';
import { mainSocket } from '../../../../socket';
import { User } from '../../../../types';
import buzzerSound from '../../../sounds/buzzer.mp3';
import './BuzzerButton.css';
import { BaseButton } from './BaseButton';

type BuzzerButtonParams = {
  user: User;
  isBuzzerLocked: boolean;
};

export const BuzzerButton = ({ user, isBuzzerLocked }: BuzzerButtonParams) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [buzzed, setBuzzed] = useState(false);

  useEffect(() => {
    if (isBuzzerLocked === true && buzzed === true) {
      setBuzzed(false);
    }
  }, [isBuzzerLocked, buzzed]);

  const handleClick = () => {
    setBuzzed(true);
    mainSocket.emit('buzzerPressed', user);
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const handleTouch = 'ontouchend' in document ? handleClick : undefined;

  return (
    <>
      {buzzed ? (
        <BaseButton glowColor={'orange'} text={'BUZZED'} />
      ) : isBuzzerLocked ? (
        <BaseButton glowColor={'red'} text={'BUZZER LOCKED'} />
      ) : (
        <BaseButton
          glowColor={'green'}
          text={'PRESS TO BUZZ'}
          handleClick={handleClick}
          onTouchStart={handleTouch}
          animated
        />
      )}
      <h3 className="connection-live-text">connection live</h3>
      <audio ref={audioRef} src={buzzerSound} />
    </>
  );
};
