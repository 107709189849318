import { useEffect, useState } from 'react';
import {
  handleAddPoint,
  handleSubtractPoint,
} from '../helpers/pointsButtonHandlers';
import { GameData } from '../../types';

type PointsBoardParams = {
  gameData: GameData;
};

interface ReducedPoints {
  team: string;
  points: number;
  id?: string;
}

export const TeamOverridePointsList = ({ gameData }: PointsBoardParams) => {
  const [fmtPoints, setFmtPoints] = useState<ReducedPoints[]>();

  useEffect(() => {
    const result = Object.entries(gameData).map(([team, players]) => {
      const totalPoints = Object.values(players).reduce(
        (acc, player) => acc + player.points,
        0
      );
      return { team, points: totalPoints };
    });

    setFmtPoints(result);
  }, [gameData]);

  return (
    <ul>
      {fmtPoints?.map(({ points, team }, i) => {
        return (
          <li key={i}>
            <button onClick={() => handleSubtractPoint({ id: 'host', team })}>
              -
            </button>
            <button onClick={() => handleAddPoint({ id: 'host', team })}>
              +
            </button>
            {` ${points} ${team}`}
          </li>
        );
      })}
    </ul>
  );
};
