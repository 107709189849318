import { hostSocket } from '../../socket';

export const getIoUpdate = () => hostSocket.emit('getUpdate');

export const getIsTeamGameStatus = (
  setIsTeamGame: React.Dispatch<React.SetStateAction<boolean>>
) => {
  fetch(`${process.env.REACT_APP_BASE_URL}/is-team-game`)
    .then((response) => response.json())
    .then(({ isTeamGameUpdate }) => {
      setIsTeamGame(isTeamGameUpdate);
    })
    .catch((err) => console.log(err));
};
