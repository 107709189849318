import { useEffect } from 'react';
import { BroadcastBuzzes } from './BroadcastBuzzes';
import { getIoUpdate } from '../helpers/api';
import { hostSocket } from '../../socket';
import { BuzzedUser } from '../../types';

type BuzzersBoardParams = {
  buzzes: BuzzedUser[];
  isTeamGame: boolean;
};

export const Buzzes = ({ buzzes, isTeamGame }: BuzzersBoardParams) => {
  useEffect(() => {
    hostSocket.connect();

    getIoUpdate();

    return () => {
      hostSocket.disconnect();
    };
  }, []);

  return (
    <div className="buzzesTableContainer">
      <div
        className="squadGamesLogo"
        style={{ marginLeft: buzzes.length > 0 ? '0px' : '30px' }}
      >
        <img
          src="images/squad-games-logo.png"
          alt=""
          height={'auto'}
          width={'750px'}
        />
        <h5 className="underImageText">QUICKEST TO BUZZ</h5>
      </div>
      <BroadcastBuzzes buzzes={buzzes} isTeamGame={isTeamGame} />
    </div>
  );
};
