import { User } from '../../../types';
import { BaseButton } from './BuzzerButton/BaseButton';
import { BuzzerButton } from './BuzzerButton/BuzzerButton';
import { PlayerDetails } from './PlayerDetails/PlayerDetails';
import './PlayerView.css';

type PlayerViewParams = {
  user: User;
  isBuzzerLocked: boolean;
  isConnected: boolean;
};

export const PlayerView = ({
  user,
  isConnected,
  isBuzzerLocked,
}: PlayerViewParams) => {
  return (
    <div className="player-view-container">
      <PlayerDetails user={user} />
      {isConnected ? (
        <BuzzerButton isBuzzerLocked={isBuzzerLocked} user={user} />
      ) : (
        <>
          <BaseButton glowColor="blue" text="CONNECTING" animated />
          <h3 className="connection-live-text">.</h3>
        </>
      )}
    </div>
  );
};
