import {
  handleDeleteAllUsers,
  handleGameMode,
} from '../helpers/hostControlHandlers';

export const GameModeControls = ({ isTeamGame }: { isTeamGame: boolean }) => (
  <div>
    <button onClick={handleGameMode}>
      game mode: {isTeamGame ? 'team' : 'single'}
    </button>

    <button onClick={handleDeleteAllUsers}>
      delete everything {'(new game)'}
    </button>
  </div>
);
