import { useEffect, useState } from 'react';
import { isEmptyObject } from '../helpers/hostControlHandlers';
import {
  handleAddPoint,
  handleSubtractPoint,
} from '../helpers/pointsButtonHandlers';
import { BuzzedUser, GameData } from '../../types';

type HostBuzzesListParams = {
  isTeamGame: boolean;
  buzzes: BuzzedUser[];
  gameData: GameData;
};

export const BuzzesList = ({
  isTeamGame,
  buzzes,
  gameData,
}: HostBuzzesListParams) => {
  const [playerHasBuzzed, setPlayerHasBuzzed] = useState(false);
  const [totalPlayers, setTotalPlayers] = useState(0);

  useEffect(() => {
    setPlayerHasBuzzed(() => buzzes.length > 0 && !isEmptyObject(gameData));
  }, [buzzes, gameData]);

  useEffect(() => {
    setTotalPlayers(0);

    Object.entries(gameData).forEach(([name, team]) => {
      const numberOfPlayerInTeam = Object.keys(team).length;
      setTotalPlayers((prev) => prev + numberOfPlayerInTeam);
    });

    if (isTeamGame) {
      const hostTeamOverrides = 16;
      setTotalPlayers((prev) => prev - hostTeamOverrides);
    }
  }, [isTeamGame, gameData]);

  return (
    <>
      <h5>
        {`${Math.round((buzzes.length / totalPlayers) * 100) || 0}% buzzed - ${
          buzzes.length
        } of ${totalPlayers} total players`}
      </h5>
      {playerHasBuzzed ? (
        <ul>
          {buzzes.slice(0, 30).map(({ id, name, team }, index) => (
            <li key={index}>
              {`${name} ${isTeamGame && 'from ' + team} ${
                gameData[team][id].points
              } `}
              <button onClick={() => handleSubtractPoint({ id, team })}>
                -
              </button>
              <button onClick={() => handleAddPoint({ id, team })}>+</button>
            </li>
          ))}
        </ul>
      ) : (
        <h6>waiting for players to buzz</h6>
      )}
    </>
  );
};
