import { useState } from 'react';
import { User } from '../../../types';
import './JoinForm.css';

type JoinFormParams = {
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  isTeamGame: boolean;
  user: User | undefined;
};

const teams = [
  { label: 'Allsorts', value: 'Allsorts' },
  { label: 'Bombe_squad', value: 'Bombe_squad' },
  { label: 'Boost', value: 'Boost' },
  { label: 'FOKAS', value: 'FOKAS' },
  { label: 'High5', value: 'High5' },
  { label: 'HODgepotch', value: 'HODgepotch' },
  { label: 'Kermit the squad', value: 'Kermit' },
  { label: 'Myth Squad', value: 'Myth' },
  { label: 'Snickers', value: 'Snickers' },
  { label: 'So Solid Squad', value: 'So Solid' },
  { label: 'Sparck', value: 'Sparck' },
  { label: 'Squad core processing', value: 'Squad core' },
  { label: 'Still-in-beta', value: 'Still-in-beta' },
  { label: 'SWOT', value: 'SWOT' },
  { label: 'The Wright Stuff', value: 'Wright Stuff' },
  { label: 'Tycho', value: 'Tycho' },
];

export const JoinForm = ({ setUser, isTeamGame, user }: JoinFormParams) => {
  const [name, setName] = useState('');
  const [team, setTeam] = useState('');
  const [nameErr, setNameErr] = useState(false);
  const [teamErr, setTeamErr] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!name || (!team && isTeamGame)) {
      if (!name.length) {
        setNameErr(true);
      }

      if (!team && isTeamGame) {
        setTeamErr(true);
      }
      return;
    }

    const id = crypto.randomUUID();
    const fmtName = name.toUpperCase();
    const fmtTeam = isTeamGame ? team : id;

    const newUser = {
      name: fmtName,
      team: fmtTeam,
      id,
    };

    sessionStorage.setItem('buzzerUser', JSON.stringify(newUser));
    setUser(newUser);
  };

  return (
    <div className="formContainer">
      <h2>JOIN</h2>
      <form onSubmit={(e) => handleSubmit(e)}>
        {nameErr && <p>Please enter your display name</p>}
        <input
          type="text"
          maxLength={11}
          value={name}
          onChange={(e) => {
            setNameErr(false);
            setName(e.target.value.trim());
          }}
          placeholder="Display Name"
        />
        {isTeamGame && (
          <>
            {teamErr && <p>Please select a squad</p>}
            <select
              onChange={(e) => {
                setTeamErr(false);
                setTeam(e.target.value);
              }}
            >
              <option hidden value="">
                Squad
              </option>
              {teams.map(({ label, value }, i) => {
                return (
                  <option key={i} value={value}>
                    {label}
                  </option>
                );
              })}
            </select>
          </>
        )}
        <button type="submit">Go</button>
      </form>
    </div>
  );
};
