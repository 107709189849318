export type User = {
  id: string;
  name: string;
  team: string;
};
type Team = {
  [key: string]: { name: string; points: number; active: boolean };
};

export type GameData = {
  [key: string]: Team;
};

export type BuzzedUser = {
  id: string;
  name: string;
  team: string;
  timeBuzzed: number;
};

export const colors: Record<string, string> = {
  Allsorts: '#4D4077',
  Bombe_squad: '#486539',
  Boost: '#A79232',
  FOKAS: '#2C5877',
  High5: '#884B44',
  HODgepotch: '#41364C',
  Kermit: '#536714',
  Myth: '#80664C',
  Snickers: '#B35A2D',
  'So Solid': '#343F6F',
  Sparck: '#6C4D6A',
  'Squad core': '#424C38',
  'Still-in-beta': '#4B4A59',
  SWOT: '#BB7787',
  'Wright Stuff': '#50657C',
  Tycho: '#5E1E1C',
};
