import { hostSocket } from '../../socket';
import { GameData } from '../../types';

export const handleClear = () => hostSocket.emit('clearBuzzes');
export const handleLock = () => hostSocket.emit('lockBuzzers');
export const handleGameMode = () => console.log('yo! single player disabled');

export const handleDeleteUser = ({
  id,
  team,
}: {
  id: string;
  team: string;
}) => {
  hostSocket.emit('deleteUser', { id, team });
};
export const handleDeleteAllUsers = () => hostSocket.emit('deleteAllUsers');

export const isEmptyObject = (points: GameData) => {
  for (const keys in points) {
    return false;
  }
  return true;
};
