import { hostSocket } from '../../socket';

export const handleAddPoint = ({ id, team }: { id: string; team: string }) => {
  hostSocket.emit('updatePoints', {
    id,
    team,
    pointsUpdate: +1,
  });
};

export const handleSubtractPoint = ({
  id,
  team,
}: {
  id: string;
  team: string;
}) => {
  hostSocket.emit('updatePoints', {
    id,
    team,
    pointsUpdate: -1,
  });
};

export const handleBankPoints = ({
  id,
  team,
  points,
}: {
  id: string;
  team: string;
  points: number;
}) => {
  hostSocket.emit('updatePoints', {
    id,
    team,
    pointsUpdate: points,
  });
};
