import { useEffect, useState } from 'react';
import { GameData, colors } from '../../types';

type PointsBoardParams = {
  gameData: GameData;
  isTeamGame: boolean;
};

interface ReducedPoints {
  team: string;
  points: number;
  id?: string;
}

export const PointsBoard = ({ gameData, isTeamGame }: PointsBoardParams) => {
  const [fmtPoints, setFmtPoints] = useState<ReducedPoints[]>();

  useEffect(() => {
    const result = Object.entries(gameData).map(([idOrTeam, players]) => {
      const totalPoints = Object.values(players).reduce(
        (acc, player) => acc + player.points,
        0
      );
      const team = isTeamGame ? idOrTeam : gameData[idOrTeam][idOrTeam].name;
      return {
        team,
        points: totalPoints,
        fill: colors[team] || 'grey',
      };
    });

    setFmtPoints(result);
  }, [gameData, isTeamGame]);

  return (
    <div className="squadPoints">
      <ol>
        {fmtPoints
          ?.sort((a, b) => b.points - a.points)
          .map((squad, index) => {
            return (
              <li
                key={index}
                style={{
                  backgroundColor: colors[squad.team],
                }}
              >
                <h5>{squad.team.toUpperCase()}</h5>
                <h4 className="broadcastNumber">{squad.points}</h4>
              </li>
            );
          })}
      </ol>
    </div>
  );
};
