import { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Landing } from './components/landing/Landing';
import { Host } from './components/host/Host';
import { Broadcast } from './components/broadcast/Broadcast';
import { Play } from './components/play/Play';
import { hostSocket, mainSocket } from './socket';
import ReactDOM from 'react-dom/client';
import { Buzzes } from './components/buzzesBoard/Buzzes';
import { BuzzedUser, GameData } from './types';

export const App = () => {
  const [isConnected, setIsConnected] = useState(mainSocket.connected);
  const [buzzes, setBuzzesEvents] = useState<BuzzedUser[]>([]);
  const [isBuzzerLocked, setIsBuzzerLocked] = useState(true);
  const [gameData, setGameData] = useState<GameData>({});
  const [isRemoved, setIsRemoved] = useState(false);

  const isTeamGame = true;

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true);
    };

    const onDisconnect = () => setIsConnected(false);

    const onBuzzesEvent = (buzzesUpdate: BuzzedUser[]) => {
      setBuzzesEvents(buzzesUpdate);
    };

    const onDeleteUser = () => {
      setIsRemoved(true);
    };

    const onPointsUpdate = (update: GameData) => {
      setGameData(update);
    };

    const onHostConnect = () => {
      //
    };

    const onAreBuzzersLocked = (update: boolean) => {
      setIsBuzzerLocked(update);
    };

    mainSocket.on('connect', onConnect);
    mainSocket.on('deleteUser', onDeleteUser);
    mainSocket.on('disconnect', onDisconnect);
    hostSocket.on('buzzes', onBuzzesEvent);
    hostSocket.on('gameDataUpdate', onPointsUpdate);
    hostSocket.on('connect', onHostConnect);
    mainSocket.on('areBuzzersLocked', onAreBuzzersLocked);
    hostSocket.on('areBuzzersLocked', onAreBuzzersLocked);

    return () => {
      mainSocket.off('connect', onConnect);
      mainSocket.off('deleteUser', onDeleteUser);
      mainSocket.off('disconnect', onDisconnect);
      hostSocket.off('buzzes', onBuzzesEvent);
      hostSocket.off('gameDataUpdate', onPointsUpdate);
      hostSocket.off('connect', onHostConnect);
      mainSocket.off('areBuzzersLocked', onAreBuzzersLocked);
      hostSocket.off('areBuzzersLocked', onAreBuzzersLocked);
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          index
          element={
            <div className="mainContainer">
              <h1 style={{ color: 'white' }}>We'll be back soon</h1>
            </div>
          }
        />
        <Route
          path="host"
          element={
            <Host
              isTeamGame={isTeamGame}
              buzzes={buzzes}
              gameData={gameData}
              isBuzzerLocked={isBuzzerLocked}
            />
          }
        />
        <Route
          path="broadcast"
          element={
            <Broadcast
              buzzes={buzzes}
              gameData={gameData}
              isTeamGame={isTeamGame}
            />
          }
        />
        <Route
          path="buzzes"
          element={<Buzzes buzzes={buzzes} isTeamGame={isTeamGame} />}
        />
        <Route
          path="play"
          element={
            <Play
              isConnected={isConnected}
              isBuzzerLocked={isBuzzerLocked}
              isTeamGame={isTeamGame}
              isRemoved={isRemoved}
            />
          }
        />
        <Route path="real" element={<Landing />} />

        <Route path="*" element={<h1>for oh four</h1>} />
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);
