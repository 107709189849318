import { handleClear, handleLock } from '../helpers/hostControlHandlers';

type HostBuzzerControlsParams = {
  isBuzzerLocked: boolean;
  buzzed: number;
};
export const HostBuzzerControls = ({
  isBuzzerLocked,
  buzzed,
}: HostBuzzerControlsParams) => {
  const haveSomeBuzzed = buzzed > 0;
  return (
    <div className="hostBuzzerButtonsContainter">
      <button onClick={handleClear} className="hostBuzzerControl">
        Clear
      </button>{' '}
      <button onClick={handleLock} className="hostBuzzerControl">
        {isBuzzerLocked ? 'Unlock' : 'Lock'}
      </button>
      <h6
        className="buzzerLockStatus"
        style={{
          backgroundColor: isBuzzerLocked
            ? 'red'
            : haveSomeBuzzed
            ? 'orange'
            : 'green',
          width: '80px',
          height: '80px',
        }}
      >
        {isBuzzerLocked
          ? 'all locked'
          : haveSomeBuzzed
          ? 'some locked'
          : 'all unlocked'}
      </h6>
    </div>
  );
};
