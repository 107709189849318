import { io } from 'socket.io-client';

if (!process.env.REACT_APP_BASE_URL) {
  throw new Error('no base url');
}

export const mainSocket = io(process.env.REACT_APP_BASE_URL, {
  autoConnect: false,
});
export const hostSocket = io(process.env.REACT_APP_BASE_URL + '/host', {
  autoConnect: false,
});
