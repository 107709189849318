import { useEffect, useState } from 'react';
import { JoinForm } from './JoinForm/JoinForm';
import { mainSocket } from '../../socket';
import { User } from '../../types';
import { PlayerView } from './PlayerView/PlayerView';
import './Play.css';

type PlayParams = {
  isConnected: boolean;
  isBuzzerLocked: boolean;
  isTeamGame: boolean;
  isRemoved: boolean;
};

export const Play = ({
  isConnected,
  isBuzzerLocked,
  isTeamGame,
  isRemoved,
}: PlayParams) => {
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    //controls if a user is deleted by host
    if (isRemoved) {
      setUser(undefined);
      sessionStorage.setItem('buzzerUser', JSON.stringify({}));
    }
  }, [isRemoved]);

  useEffect(() => {
    // checking if user is stored
    mainSocket.connect();
    const isUserStored = sessionStorage.getItem('buzzerUser');
    if (isUserStored) {
      const storedUser = JSON.parse(isUserStored);
      if (storedUser.id && storedUser.name && storedUser.team) {
        setUser(storedUser);
      }
    }
    return () => {
      mainSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (user?.id && user?.name) {
      sessionStorage.setItem('buzzerUser', JSON.stringify(user));
      mainSocket.emit('join', user);
    }
  }, [user, isConnected]);

  // can we open another sign up page here first for the room you want to enter

  return (
    <div className="play-container">
      {!user && (
        <JoinForm setUser={setUser} user={user} isTeamGame={isTeamGame} />
      )}
      {user && (
        <PlayerView
          user={user}
          isBuzzerLocked={isBuzzerLocked}
          isConnected={isConnected}
        />
      )}
    </div>
  );
};
