import { useEffect } from 'react';
import { PointsBoard } from './PointsBoard';
import { getIoUpdate } from '../helpers/api';

import { hostSocket } from '../../socket';
import { BuzzedUser, GameData } from '../../types';

type BroadcastParams = {
  buzzes: BuzzedUser[];
  gameData: GameData;
  isTeamGame: boolean;
};

export const Broadcast = ({ gameData, isTeamGame }: BroadcastParams) => {
  useEffect(() => {
    hostSocket.connect();

    getIoUpdate();

    return () => {
      hostSocket.disconnect();
    };
  }, []);

  return (
    <div className="buzzesTableContainer">
      <div className="squadGamesLogo">
        <img
          src="images/squad-games-logo.png"
          alt=""
          height={'auto'}
          width={'750px'}
        />
        <h5 className="underImageText">MUSIC QUIZ LEADERBOARD</h5>
      </div>
      <PointsBoard gameData={gameData} isTeamGame={isTeamGame} />
    </div>
  );
};
