import { GameData } from '../../types';
import {
  handleDeleteUser,
  isEmptyObject,
} from '../helpers/hostControlHandlers';
import {
  handleAddPoint,
  handleBankPoints,
  handleSubtractPoint,
} from '../helpers/pointsButtonHandlers';

type HostPointsOverrideControlsParams = {
  gameData: GameData;
  isTeamGame: boolean;
  buzzed: number;
};

export const PlayerConnectionStatusList = ({
  gameData,
  isTeamGame,
  buzzed,
}: HostPointsOverrideControlsParams) => {
  const userHasJoined = !isEmptyObject(gameData);

  if (!userHasJoined) return <h6>waiting for players to join</h6>;

  return (
    <>
      <ul>
        {Object.entries(gameData).map(([idOrTeam, team]) =>
          Object.entries(team)
            .filter(([id, _]) => {
              if (isTeamGame) {
                return id !== 'host';
              } else {
                return true;
              }
            })
            .map(([id, { points, name, active }]) => (
              <li key={id} style={{ color: active ? '#3f6f27' : '#894640' }}>
                {' '}
                {name} {isTeamGame && `from ${idOrTeam}`} - points{' '}
                {points + ' '}
                <button
                  onClick={() => handleSubtractPoint({ id, team: idOrTeam })}
                >
                  -
                </button>
                <button onClick={() => handleAddPoint({ id, team: idOrTeam })}>
                  +
                </button>
                {active ? ' online ' : ' offline '}
                <button
                  onClick={() => {
                    if (!buzzed) {
                      if (isTeamGame) {
                        handleBankPoints({
                          id: 'host',
                          team: idOrTeam,
                          points,
                        });
                      }
                      handleDeleteUser({ id, team: idOrTeam });
                    }
                  }}
                >
                  x
                </button>
              </li>
            ))
        )}
      </ul>
    </>
  );
};
