type BaseButtonParams = {
  glowColor: string;
  text: string;
  handleClick?: () => void;
  onTouchStart?: (() => void) | undefined;
  animated?: boolean;
};

export const BaseButton = ({
  glowColor,
  text,
  handleClick,
  onTouchStart,
  animated,
}: BaseButtonParams) => (
  <>
    <div className="back-disc">
      <div
        className="front-disc"
        onClick={handleClick}
        onTouchStart={onTouchStart}
        style={{ cursor: handleClick ? 'pointer' : undefined }}
      >
        {text}
      </div>
      <div
        className={animated ? 'glow-disc-animated' : 'glow-disc'}
        style={{ backgroundColor: glowColor }}
      ></div>
    </div>
  </>
);
